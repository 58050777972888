export const fetchBackend = (
  path: Parameters<WindowOrWorkerGlobalScope["fetch"]>[0],
  options?: Parameters<WindowOrWorkerGlobalScope["fetch"]>[1],
) => {
  return window.fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...options,
  });
};

export const fetchMAS = (
  path: Parameters<WindowOrWorkerGlobalScope["fetch"]>[0],
  options: Parameters<WindowOrWorkerGlobalScope["fetch"]>[1],
) => {
  return window.fetch(`${process.env.REACT_APP_MAS_URL}${path}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_MAS_TOKEN}`,
    },
    ...options,
  });
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const authenticate = (email: string, password: string) => {
  let isValid = false;
  switch (email) {
    case "stacey@ronati.com":
      isValid = password === "Password1";
      break;
    case "findenginedemo@ronati.com":
      isValid = password === "Password1";
      break;
    default:
      break;
  }
  if (isValid) {
    localStorage.setItem("auth", "true");
  }
  return isValid;
};

export const logout = () => {
  localStorage.removeItem("auth");
};
