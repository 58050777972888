import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classNames from "clsx";
import { Link, Outlet } from "react-router-dom";
import { logout } from "../utils";
import { AuthGuard } from "./AuthGuard";

export function Layout() {
  return (
    <div className="flex flex-col flex-1">
      <header className="sticky top-0 z-30 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-md shadow-slate-900/5 transition duration-500 sm:px-6 lg:px-8">
        <div className="relative flex items-center basis-0 grow">
          <Link
            to="/"
            aria-label="Home page"
            className="font-serif text-2xl sm:text-4xl uppercase text-aegean font-medium"
          >
            Ronati
          </Link>
        </div>
        <AuthGuard redirect={false}>
          <div className="ml-6 flex items-center">
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-aegean-500 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/login"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700",
                        )}
                        onClick={() => {
                          logout();
                        }}
                      >
                        Sign out
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </AuthGuard>
      </header>

      <main id="main-content">
        <div className="w-full min-w-0 max-w-2xl flex-auto px-4 py-16 lg:max-w-none lg:w-5/6 lg:mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
