import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useLayoutEffect } from "react";

import { Title, Notifications } from "../components";
import { AuthGuard } from "../components/AuthGuard";
import { fetchBackend, fetchMAS, toBase64 } from "../utils";

export const ATTRIBUTE_LIST = [
  { key: "period", label: "Period" },
  { key: "style", label: "Style" },
  { key: "maker", label: "Maker" },
  { key: "designer", label: "Designer" },
  { key: "artist", label: "Artist" },
  { key: "condition", label: "Condition" },
  { key: "color", label: "Color" },
  { key: "material", label: "Material" },
  { key: "origin", label: "Origin" },
  { key: "height", label: "Height" },
  { key: "width", label: "Width" },
  { key: "depth", label: "Depth" },
  { key: "diameter", label: "Diameter" },
  { key: "weight", label: "Weight" },
  { key: "ship_from_country", label: "Ship From Country" },
  { key: "ship_to_country", label: "Ship To Country" },
  { key: "needed_by", label: "Needed by Date", type: "date" },
];

const initialValues = {
  email: "",
  name: "",
  title: "",
  description: "",
  minPrice: "",
  maxPrice: "",
  reason: "",
  ...Object.fromEntries(ATTRIBUTE_LIST.map(attribute => [attribute.key, ""])),
};

interface Image {
  src: string;
  name: string;
  loading: boolean;
}

export default function NewRequest() {
  const [showNotification, setShowNotification] = useState(false);
  const [images, setImages] = useState<Image[]>([]);
  const [collectionId, setCollectionId] = useState<string>("");

  useLayoutEffect(() => {
    document.title = "Create Request";
  }, []);

  const createCollection = async () => {
    const rawResponse = await fetchMAS("/collection", {
      method: "POST",
      body: JSON.stringify({
        owner: "ecm-1234",
      }),
    });
    const content = await rawResponse.json();
    setCollectionId(content.collectionId);
    return content.collectionId;
  };

  const createAsset = async (img: Image, file: File) => {
    let currentCollection = collectionId;
    if (!currentCollection) {
      currentCollection = await createCollection();
    }
    const res = await fetchMAS("/asset", {
      method: "POST",
      body: JSON.stringify({
        collectionId: currentCollection,
        origin: "Upload",
        type: "IMAGE",
        name: img.name,
      }),
    });
    const { putUrl } = await res.json();
    await fetch(putUrl, {
      method: "PUT",
      body: file,
    });
    setImages(state => {
      const newImageList = [...state];
      const currentImage = newImageList.find(i => i.name === img.name);
      if (currentImage) {
        currentImage.loading = false;
      }
      return newImageList;
    });
  };

  const onFileChange = async (e: any) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const base64 = (await toBase64(files[0])) as string;
      const image = { name: files[0].name, src: base64, loading: true };
      setImages(state => {
        return [image, ...state];
      });
      await createAsset(image, files[0]);
    }
  };

  const handleSubmit = async (
    {
      email,
      name,
      title,
      description,
      minPrice,
      maxPrice,
      reason,
      ...attributes
    }: any,
    resetForm: () => void,
  ) => {
    if (collectionId) {
      await fetchMAS(`/commit/collection/${collectionId}`, {
        method: "POST",
      });
    }
    const rawResponse = await fetchBackend("/requests", {
      method: "POST",
      body: JSON.stringify({
        requestor: {
          userId: email,
          name,
          email,
        },
        request: {
          title,
          description,
          minPrice,
          maxPrice,
          reason,
          masCollectionId: collectionId,
          attributes: {
            ...attributes,
          },
        },
      }),
    });
    await rawResponse.json();

    setShowNotification(true);
    setImages([]);
    resetForm();
  };

  return (
    <AuthGuard>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Title>New request</Title>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-8 px-4 shadow sm:px-10">
            <Formik
              initialValues={initialValues}
              validate={values => {
                const errors: Record<string, string> = {};
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                Object.entries(values).forEach(([key, value]) => {
                  if (!value && !ATTRIBUTE_LIST.map(a => a.key).includes(key)) {
                    errors[key] = "Required";
                  }
                });
                return errors;
              }}
              onSubmit={async (values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
            >
              {({ isSubmitting }) => (
                <Form className="space-y-6 divide-y-2 divide-gray-400">
                  <div className="pt-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-aegean">
                        Requestor information
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Use a permanent address where you can receive mail.
                      </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-aegean">
                        Request information
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        We'll always let you know about important changes, but
                        you pick what else you want to hear about.
                      </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Title
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="title"
                            id="title"
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <Field
                            as="textarea"
                            id="description"
                            name="description"
                            rows={3}
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Write a few sentences about the request.
                        </p>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Min price
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            autoComplete="address-level2"
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="minPrice"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Max price
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            autoComplete="address-level1"
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="maxPrice"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="reason"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Project/Client
                        </label>
                        <div className="mt-1">
                          <Field
                            as="textarea"
                            id="reason"
                            name="reason"
                            rows={2}
                            className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                          />
                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="text-sm text-red-500"
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Write a few sentences about the request.
                        </p>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Photos
                        </label>
                        <div className="mt-1 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="p-1 h-28 flex justify-center rounded-md border-2 border-dashed border-gray-300 col-span-2">
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white font-medium text-coral-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-coral-500 focus-within:ring-offset-2 hover:text-coral-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={onFileChange}
                                    accept=".png, .jpg, .jpeg"
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">PNG, JPG</p>
                            </div>
                          </div>
                          {images.map((image, idx) => {
                            return (
                              <div key={idx} className="h-28 col-span-2">
                                <img
                                  src={image.src}
                                  alt="Product preview"
                                  className={`object-cover h-full w-full
                                   ${
                                     image.loading
                                       ? "blur-[2px] animate-pulse"
                                       : ""
                                   }
                                  `}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-aegean">
                        Attributes
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Attributes of the desired product
                      </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      {ATTRIBUTE_LIST.map((attribute, index) => {
                        return (
                          <div className="sm:col-span-3" key={index}>
                            <label
                              htmlFor={attribute.key}
                              className="block text-sm font-medium capitalize text-gray-700"
                            >
                              {attribute.label}
                            </label>
                            <div className="mt-1">
                              <Field
                                type={attribute.type || "text"}
                                name={attribute.key}
                                id={attribute.key}
                                className="block w-full border-gray-300 shadow-sm focus:border-coral-500 focus:ring-coral-500 sm:text-sm"
                              />
                              <ErrorMessage
                                name={attribute.key}
                                component="div"
                                className="text-sm text-red-500"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="pt-8">
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-16 inline-flex justify-center border border-transparent bg-aegean-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-coral-700 focus:outline-none focus:ring-2 focus:ring-coral-500 focus:ring-offset-2"
                      >
                        {isSubmitting ? spinner : "Save"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <Notifications
        show={showNotification}
        onClose={() => setShowNotification(false)}
        title="Successfully submitted request!"
        type="success"
      />
    </AuthGuard>
  );
}

const spinner = (
  <svg
    className="animate-spin h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
