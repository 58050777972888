import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../utils";

const initialValues = {
  email: "",
  password: "",
};

export default function Login() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:px-10">
            <Formik
              initialValues={initialValues}
              validate={values => {
                const errors: Record<string, string> = {};
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                Object.entries(values).forEach(([key, value]) => {
                  if (!value) errors[key] = "Required";
                });
                console.log(errors);

                return errors;
              }}
              onSubmit={({ email, password }, { setSubmitting, setErrors }) => {
                setSubmitting(false);
                if (authenticate(email, password)) {
                  navigate("/", { replace: true });
                } else {
                  setErrors({
                    email: "Wrong email or password",
                    password: "Wrong email or password",
                  });
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <Field
                        name="email"
                        type="text"
                        className="block w-full appearance-none border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-coral-500 focus:outline-none focus:ring-coral-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <Field
                        name="password"
                        type="password"
                        className="block w-full appearance-none border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-coral-500 focus:outline-none focus:ring-coral-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex w-full justify-center border border-transparent bg-aegean-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-aegean-700 focus:outline-none focus:ring-2 focus:ring-aegean-500 focus:ring-offset-2"
                    >
                      {isSubmitting ? "asd" : "Save"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
