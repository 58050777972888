import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { UserIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import classNames from "clsx";

import { fetchBackend, fetchMAS } from "../utils";
import {
  RequestDetailsContentLoader,
  RequestDetailsImagesContentLoader,
} from "../components";
import { ATTRIBUTE_LIST } from "../pages/NewRequest";

export function RequestDetails() {
  const { requestId } = useParams();
  const { data, isLoading: isLoadingRequest } = useQuery<any>(
    ["request", requestId],
    async ({ signal, queryKey }) => {
      const [, id] = queryKey;
      const response = await fetchBackend(`/requests/${id}`, {
        signal,
      });

      if (!response.ok) {
        throw new Error("error while fetching request information");
      }

      return response.json();
    },
  );

  let { request = {}, ...rest } = data || {};
  request = {
    ...request,
    ...rest,
  };

  const { data: assets, isLoading: isLoadingAssets } = useQuery(
    ["request", requestId, "assets"],
    async ({ signal }) => {
      const response = await fetchMAS(
        `/collection/${request?.masCollectionId}/assets`,
        { signal },
      );

      if (!response.ok) {
        throw new Error("Error while fetching request assets");
      }

      return response.json();
    },
    { enabled: !!request?.masCollectionId },
  );

  if (!request || isLoadingRequest) {
    return <RequestDetailsContentLoader />;
  }

  return (
    <div>
      <Label>Item</Label>
      <h2 className="text-2xl mb-5">{request.title}</h2>

      <Label>Date</Label>
      <p className="text-sm text-black mb-5">
        {format(new Date(request.creationTimestamp), "dd/MM/yyyy HH:mm")}
      </p>

      <Label>Price range</Label>
      <p className="text-lg mb-5">{`$${request.minPrice.toLocaleString()} - ${request.maxPrice.toLocaleString()}`}</p>

      <Label>Requesting user</Label>
      <address className="text-lg flex items-center not-italic mb-5 mt-2">
        <div className="bg-aegean-50 rounded-full p-1 mr-2">
          <UserIcon className="h-6 w-6 text-aegean" />
        </div>
        <span>{request.requestor.name}</span>
      </address>

      <Label>Project / Client</Label>
      <p className="text-lg mb-5">{request.reason}</p>

      <Label>Description</Label>
      <p className="text-lg mb-5">{request.description}</p>

      <Label>Attributes</Label>
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-3 gap-3">
        {ATTRIBUTE_LIST.map(({ key, label }) => (
          <li key={key} className="flex flex-col">
            <span className="text-sm">{label}</span>
            <span className="text-lg">
              {request.attributes?.[key] ?? "N/A"}
            </span>
          </li>
        ))}
      </ul>

      <Label className="mt-5">Images</Label>
      <div className="mt-5 flex flex-wrap gap-3">
        {isLoadingAssets ? (
          <RequestDetailsImagesContentLoader />
        ) : (
          <>
            {assets.map((asset: any) => (
              <img className="w-[49%]" src={asset.getUrl} alt="" />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function Label({ children, className }: any) {
  return (
    <h3
      className={classNames(
        "text-ronatiGrey-800 font-bold text-xs leading-normal",
        className,
      )}
    >
      {children}
    </h3>
  );
}
