import { BrowserRouter, Routes, Route } from "react-router-dom";

import NewRequest from "./pages/NewRequest";
import { Home } from "./pages/Home";
import { RequestDetails } from "./pages/RequestDetails";
import { Layout } from "./components";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />}>
            <Route path="requests/:requestId" element={<RequestDetails />} />
          </Route>
          <Route path="requests/create" element={<NewRequest />} />
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
