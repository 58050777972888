import * as React from "react";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/20/solid";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import classNames from "clsx";
import { useQuery } from "@tanstack/react-query";

import { FindRequest } from "../types";
import { Title, TableContentLoader } from "../components";
import { fetchBackend } from "../utils";
import { TableFilters, SidePanel } from "../components";
import { AuthGuard } from "../components/AuthGuard";

const columns: ColumnDef<FindRequest, any>[] = [
  {
    header: "Date received",
    accessorFn: row => new Date(row.creationTimestamp),
    cell: ({ cell }) => format(cell.getValue(), "d MMMM yyyy"),
    filterFn: "includesString",
    sortingFn: "datetime",
  },
  {
    id: "Requestor",
    header: "Requestor",
    accessorKey: "requestor.name",
    filterFn: "includesString",
  },
  {
    id: "Project",
    header: "Project",
    accessorKey: "reason",
    filterFn: "includesString",
  },
  {
    header: "Price",
    accessorFn: row =>
      `$${row.minPrice.toLocaleString()} - $${row.maxPrice.toLocaleString()}`,
    sortingFn: (rowA, rowB) => {
      const maxPriceA = rowA.original.maxPrice;
      const maxPriceB = rowB.original.maxPrice;

      if (maxPriceA < maxPriceB) {
        return -1;
      } else if (maxPriceA > maxPriceB) {
        return 1;
      }

      return 0;
    },
    filterFn: (row, _columnId, filterValue) => {
      const { minPrice, maxPrice } = row.original;
      const { minPrice: minFilter, maxPrice: maxFilter } = filterValue;
      const maxFilterExists = typeof maxFilter === "number";
      const minFilterExists = typeof minFilter === "number";

      if (minFilterExists && !maxFilterExists && minFilter <= maxPrice) {
        return true;
      } else if (maxFilterExists && !minFilterExists && maxFilter >= minPrice) {
        return true;
      } else if (minFilterExists && maxFilterExists) {
        return (
          (minPrice <= minFilter && minFilter <= maxPrice) ||
          (minPrice <= maxFilter && maxFilter <= maxPrice) ||
          (minFilter <= minPrice && maxFilter >= maxPrice)
        );
      }

      return false;
    },
  },
  {
    id: "Title",
    header: "Title",
    accessorKey: "title",
    filterFn: "includesString",
  },
];

export function Home() {
  const { data, isLoading } = useQuery(["requests"], async () => {
    const response = await fetchBackend("/requests");
    const body = await response.json();

    if (!response.ok) {
      console.error(body);
      throw new Error("Unexpected error when fetching");
    }

    return body;
  });

  React.useLayoutEffect(() => {
    document.title = "Find Engine";
  }, []);

  const matchInfo = useMatch("/requests/:requestId");
  const [sidebarOpen, setSidebarOpen] = React.useState(!!matchInfo);
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );

  const tableData = React.useMemo(() => {
    return (
      data?.map((row: any) => {
        const { request, ...rest } = row;
        return {
          ...request,
          ...rest,
        };
      }) || []
    );
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <AuthGuard>
      <div className="px-4 sm:px-6 lg:px-8 flex flex-col">
        <Title>Ronati Find Requests</Title>

      <div>
        <TableFilters
          table={table}
          onFilter={(fieldName: string, values: any) => {
            const column = table.getColumn(fieldName);
            column.setFilterValue(() => values);
          }}
        />
      </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {table.getHeaderGroups()[0].headers.map(header => (
                        <th
                          key={header.id}
                          scope="col"
                          className={classNames(
                            "text-left text-sm font-semibold text-gray-900",
                            {
                              "py-3.5 pl-4 pr-3 sm:pl-6": header.index === 0,
                              "px-3 py-3.5": header.index > 0,
                            },
                          )}
                        >
                          <button
                            type="button"
                            className={classNames("group inline-flex", {
                              "cursor-default": !header.column.getCanSort(),
                            })}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              <span
                                className={classNames(
                                  "ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible select-none",
                                  {
                                    invisible: !header.column.getIsSorted(),
                                  },
                                )}
                              >
                                {[false, "desc"].includes(
                                  header.column.getIsSorted(),
                                ) ? (
                                  <ChevronDownIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronUpIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </>
                          </button>
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {isLoading ? (
                      <tr>
                        <td
                          colSpan={
                            table.getHeaderGroups()[0].headers.length + 1
                          }
                        >
                          <TableContentLoader className="px-4" />
                        </td>
                      </tr>
                    ) : table.getRowModel().rows.length === 0 ? (
                      <tr>
                        <td
                          className="p-2 text-center"
                          colSpan={
                            table.getHeaderGroups()[0].headers.length + 1
                          }
                        >
                          No data available
                        </td>
                      </tr>
                    ) : (
                      table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell, index) => (
                            <td
                              key={cell.id}
                              className={classNames(
                                "whitespace-nowrap py-4 text-sm",
                                {
                                  "pl-4 pr-3 font-medium text-gray-900 sm:pl-6":
                                    index === 0,
                                  "px-3 text-gray-500": index > 0,
                                },
                              )}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          ))}

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`requests/${row.original.id}`}
                              className="text-aegean"
                              onClick={() => setSidebarOpen(true)}
                            >
                              <EyeIcon className="h-5 w-6" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <SidePanel
                open={sidebarOpen}
                onClose={() => {
                  setSidebarOpen(false);
                  navigate("/");
                }}
              >
                <Outlet />
              </SidePanel>
            </div>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
}
